<template>
  <div class="play-with-wrapper">
    <div class="game-list-box">
      <div class="title-box">
        <span>{{ dataList.title }}</span>
        <ul>
          <li
            v-for="(item, index) in dataList.typeList"
            :key="index"
            :class="active == index ? 'active' : ''"
            @click="handleChange(item, index)"
          >
            {{ item }}
          </li>
        </ul>
        <a href="javascript:;" @click="shouDialog">
          <img
            :src="require('./images/service.png')"
            class="icon"
            alt=""
          />咨询客服</a
        >
      </div>
      <div class="main-box">
        <div
          class="main-item"
          v-for="(item, index) in dataArr"
          :key="index"
          @mouseover="hoverIndex = index"
          @mouseout="hoverIndex = -1"
          @click="shouDialog"
        >
          <div class="line">
            <img
              v-if="item.line"
              :src="require('./images/spot.png')"
              class="icon0"
              alt=""
            />
            <img
              v-else
              :src="require('./images/icon.gif')"
              class="icon"
              alt=""
            />
            {{ item.line ? "在线" : "语音聊天中 >" }}
          </div>
          <el-image
            style="width: 100%; height: 215px"
            :src="item.img"
            fit="cover"
            lazy
          ></el-image>

          <div class="item-wrapper">
            <img
              v-if="hoverIndex == index"
              :src="require('./images/ico_15.gif')"
              class="gif"
              alt=""
            />

            <img
              v-if="hoverIndex == index"
              :src="require('./images/icon01.png')"
              class="icon"
              alt=""
            />
            <img
              v-else
              :src="require('./images/icon02.png')"
              class="icon"
              alt=""
            />

            <div class="name">
              {{ item.name }}
            </div>
            <div class="item-game">
              <div class="game">{{ item.gameName }}</div>
              <div class="price">
                <span>{{ item.price }}</span
                >元/局
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 二维码弹窗 -->
    <el-dialog
      custom-class="dialog-wrapper"
      :visible.sync="isShow"
      width="589px"
      height="631px"
      top="10vh"
      :show-close="false"
    >
      <div class="dialog-box">
        <img
          :src="require('./images/cuo.png')"
          class="icon"
          alt=""
          @click="isShow = false"
        />
        <div class="dialog-content">
          <img :src="require('./images/erweima.png')" class="code" alt="" />
          <p>微信号：15380041550</p>
          <h1>微信扫一扫添加客服咨询</h1>
          <img
            :src="require('./images/anniu.png')"
            class="btn"
            alt=""
            @click="openQQ"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import data from "./data.js";
import cMethods from "@/util/cMethods.js";

export default {
  name: "playWith",
  data() {
    return {
      active: 0,
      hoverIndex: -1,
      dataList: {},
      dataArr: [],
      isShow: false,
    };
  },
  mounted() {
    this.dataList = data.data;
    this.dataArr = this.dataList.data;
  },
  methods: {
    toDetail(productId) {
      this.name = "";
      this.$router.push(`/gameDetail/${productId}`);
    },
    handleChange(item, index) {
      this.active = index;
      if (index == 0) {
        this.dataArr = this.dataList.data;
      } else if (index == 1 || index == 2) {
        this.dataArr = this.dataList.data.filter((obj) => {
          return obj.type == item;
        });
      } else {
        this.dataArr = this.dataList.data.filter((obj) => {
          return obj.gameName == item;
        });
      }
    },
    shouDialog() {
      this.isShow = true;
      this.$statistic(["A", "陪玩页面", "客服二维码", "展现量"]);
    },
    openQQ() {
      cMethods.OpenIeUrl(
        "http://wpa.qq.com/msgrd?v=3&uin=120130056&site=qq&menu=yes"
      );
      this.$statistic(["B", "陪玩页面", "点击", "QQ咨询"]);
    },
  },
};
</script>

<style lang="scss" scoped>
.play-with-wrapper {
  padding: 20px;
  height: 676px;
  overflow: auto;
  .game-list-box {
    padding: 20px;
    background: $white-col;
    box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-sizing: border-box;
    overflow: hidden;
    .title-box {
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e6e6e6;
      span {
        height: 22px;
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: bold;
        @include font_col3();
        margin-right: 30px;
        &::before {
          content: "";
          width: 4px;
          height: 23px;
          background: #7f4294;
          border-radius: 2px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          margin-top: -5px;
        }
      }
      ul {
        display: flex;
        li {
          margin-right: 15px;
          width: 96px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #f1f1f1;
          border-radius: 16px;
          cursor: pointer;
          &.active {
            background: #7f4294;
            color: $white-col;
          }
          &:hover {
            background: #7f4294;
            color: $white-col;
          }
        }
      }
      a {
        width: 106px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: $white-col;
        background: $linear-col;
        border-radius: 16px;
        .icon {
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
    .main-box {
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      .main-item {
        width: 185px;
        height: 285px;
        margin-right: 14px;
        margin-top: 15px;
        box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        .line {
          position: absolute;
          top: 10px;
          left: 10px;
          z-index: 99;
          padding: 0 15px;
          height: 28px;
          line-height: 28px;
          background: rgba(0, 0, 0, 0.7);
          opacity: 0.7;
          border-radius: 14px;
          color: $white-col;
          text-align: center;
          font-size: 12px;
          .icon {
            width: 14px;
            height: 16px;
            vertical-align: middle;
            margin-right: 5px;
          }
          .icon0 {
            width: 6px;
            height: 6px;
            vertical-align: middle;
            margin-right: 5px;
          }
        }
        img {
          width: 100%;
          height: 225px;
        }
        .item-wrapper {
          padding: 10px;
          .icon {
            width: 39px;
            height: 32px;
            position: absolute;
            bottom: 54px;
            right: 0;
          }
          .gif {
            width: 185px;
            height: 45px;
            position: absolute;
            bottom: 70px;
            right: 0;
          }
          .name {
            font-size: 14px;
            color: #701d8d;
            margin-bottom: 5px;
          }
          .item-game {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include font_col6();
            .price span {
              font-size: 20px;
              font-weight: bold;
              color: #f30000;
            }
          }
        }
        &:hover {
          background: #7f4294;
          cursor: pointer;
          .name {
            color: $white-col;
          }
          .item-game {
            color: $white-col;
            .price span {
              color: #fcff00;
            }
          }
        }
      }
      .main-item:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.dialog-wrapper {
  width: 589px;
  background: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
  .el-dialog__body {
    // padding: 0 !important;
    // background: transparent;
    .dialog-box {
      height: 631px !important;
      background: url("./images/dialog.png") no-repeat;
      position: relative;
      .icon {
        position: absolute;
        top: 100px;
        right: 0;
        cursor: pointer;
      }
      .dialog-content {
        width: 384px;
        height: 370px;
        position: absolute;
        left: 50%;
        bottom: 60px;
        margin-left: -192px;
        text-align: center;
        .code {
          width: 226px;
          height: 226px;
        }
        p {
          font-size: 19px;
          font-weight: bold;
          color: #9e55ff;
          line-height: 44px;
        }
        h1 {
          font-size: 24px;
          font-weight: bold;
          color: #9e55ff;
          line-height: 44px;
        }
        .btn {
          cursor: pointer;
        }
      }
    }
  }
}
</style>