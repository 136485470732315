var data = {
    "title": "人气推荐",
    "typeList": [
        "全部",
        "女神陪玩",
        "大神上分",
        "王者荣耀",
        "和平精英",
        "英雄联盟"],
    "data": [{
        "name": "峡谷王者乱杀小布",
        "gameName": "王者荣耀",
        "price": "20",
        "img": require("./images/31.png"),
        "type": "大神上分",
        "line": true,
    },
    {
        "name": "遛狗の仙女",
        "gameName": "英雄联盟",
        "price": "21",
        "img": require("./images/32.png"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "午后慵懒の少女",
        "gameName": "王者荣耀",
        "price": "22",
        "img": require("./images/33.png"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "少女の烦躁期",
        "gameName": "和平精英",
        "price": "28",
        "img": require("./images/34.png"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "﹌好奇怪の妹妹",
        "gameName": "英雄联盟",
        "price": "24",
        "img": require("./images/35.png"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "そ调香师の长裙",
        "gameName": "王者荣耀",
        "price": "25",
        "img": require("./images/36.png"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "輸zαι孤傲o",
        "gameName": "王者荣耀",
        "price": "20",
        "img": require("./images/37.png"),
        "type": "大神上分",
        "line": true,
    },
    {
        "name": "漫长の白日梦⌒",
        "gameName": "王者荣耀",
        "price": "27",
        "img": require("./images/38.jpg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "δ肯鸡腿の小胖妹﹋",
        "gameName": "和平精英",
        "price": "28",
        "img": require("./images/39.png"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "洎扰の庸人",
        "gameName": "英雄联盟",
        "price": "29",
        "img": require("./images/40.png"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "亲爱の偏執狂",
        "gameName": "王者荣耀",
        "price": "20",
        "img": require("./images/41.png"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "ご緑wμ子怪亽",
        "gameName": "和平精英",
        "price": "21",
        "img": require("./images/42.png"),
        "type": "大神上分",
        "line": true,
    },
    {
        "name": "被暴走の少女梦ヽ",
        "gameName": "王者荣耀",
        "price": "22",
        "img": require("./images/43.png"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "мо仙堡杠把子灬",
        "gameName": "王者荣耀",
        "price": "23",
        "img": require("./images/44.png"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "暴走の尐女梦",
        "gameName": "王者荣耀",
        "price": "20",
        "img": require("./images/45.png"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "骨囝里⒐孤单",
        "gameName": "和平精英",
        "price": "25",
        "img": require("./images/46.png"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "○罙海yυ不怕黑",
        "gameName": "王者荣耀",
        "price": "20",
        "img": require("./images/47.png"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "九丶钟的尒",
        "gameName": "和平精英",
        "price": "20",
        "img": require("./images/48.gif"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "占冇欲zцδ怪",
        "gameName": "英雄联盟",
        "price": "20",
        "img": require("./images/49.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "冇ㄝ台不见終",
        "gameName": "英雄联盟",
        "price": "29",
        "img": require("./images/50.jpeg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "皺眉の亽",
        "gameName": "王者荣耀",
        "price": "21",
        "img": require("./images/51.jpg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "忘ぅ催白勺姑娘",
        "gameName": "和平精英",
        "price": "20",
        "img": require("./images/52.jpg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "恶飠女郎",
        "gameName": "王者荣耀",
        "price": "20",
        "img": require("./images/53.jpg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "℃橘子zι盐汽氷",
        "gameName": "王者荣耀",
        "price": "30",
        "img": require("./images/54.jpeg"),
        "type": "大神上分",
        "line": true,
    },
    {
        "name": "≮暖忄意中亽≯",
        "gameName": "和平精英",
        "price": "30",
        "img": require("./images/55.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "╰尒是伯の猫",
        "gameName": "王者荣耀",
        "price": "23",
        "img": require("./images/56.jpg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "攒ㄚι袋星星",
        "gameName": "王者荣耀",
        "price": "20",
        "img": require("./images/57.jpeg"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "晚点吣ㄊが",
        "gameName": "王者荣耀",
        "price": "25",
        "img": require("./images/58.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "自由｀分情",
        "gameName": "王者荣耀",
        "price": "25",
        "img": require("./images/59.jpeg"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "ぃ○食君的迷妹儿",
        "gameName": "王者荣耀",
        "price": "25",
        "img": require("./images/60.jpg"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "盖世英雄のぶ迷妹",
        "gameName": "王者荣耀",
        "price": "30",
        "img": require("./images/61.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "┇θ記局外┆",
        "gameName": "王者荣耀",
        "price": "25",
        "img": require("./images/62.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "萝莉の英雄梦",
        "gameName": "王者荣耀",
        "price": "20",
        "img": require("./images/63.gif"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "Ю找ナ魚dε猫",
        "gameName": "王者荣耀",
        "price": "22",
        "img": require("./images/64.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "丬仙女の許愿池",
        "gameName": "王者荣耀",
        "price": "20",
        "img": require("./images/65.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "`章鱼ぷгομ丸灬",
        "gameName": "王者荣耀",
        "price": "25",
        "img": require("./images/66.jpg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "ぐ风雨中の旧情亽",
        "gameName": "王者荣耀",
        "price": "25",
        "img": require("./images/67.png"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "「故事ヌ隹入卩」",
        "gameName": "和平精英",
        "price": "28",
        "img": require("./images/68.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "口紅半嗰dцΘ",
        "gameName": "和平精英",
        "price": "25",
        "img": require("./images/69.jpeg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "┈永溺罙海の猫┈",
        "gameName": "和平精英",
        "price": "25",
        "img": require("./images/70.jpeg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "ご娇喘界的扛把zΙ",
        "gameName": "和平精英",
        "price": "28",
        "img": require("./images/71.gif"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "爱撒谎の渵。ら",
        "gameName": "和平精英",
        "price": "25",
        "img": require("./images/72.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "智障の小污女",
        "gameName": "和平精英",
        "price": "25",
        "img": require("./images/73.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "疒ン态女ˇ",
        "gameName": "和平精英",
        "price": "28",
        "img": require("./images/74.jpeg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "苝海莈冇猫",
        "gameName": "和平精英",
        "price": "25",
        "img": require("./images/75.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "妳dε尐女罪",
        "gameName": "和平精英",
        "price": "27",
        "img": require("./images/76.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "⊙冇er洞的猫○",
        "gameName": "和平精英",
        "price": "25",
        "img": require("./images/77.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "原汁原味dの菇凉ぼ",
        "gameName": "和平精英",
        "price": "26",
        "img": require("./images/78.jpg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "朂酷⑶suι",
        "gameName": "和平精英",
        "price": "30",
        "img": require("./images/79.jpeg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "ɑ.听风ο欠の暖软",
        "gameName": "英雄联盟",
        "price": "30",
        "img": require("./images/80.jpeg"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "︶油漆店白勺老情亽",
        "gameName": "王者荣耀",
        "price": "30",
        "img": require("./images/81.jpg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "烂掉(~)的氕球君ゅ",
        "gameName": "英雄联盟",
        "price": "30",
        "img": require("./images/82.jpeg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "骄傲のふ巫女",
        "gameName": "王者荣耀",
        "price": "26",
        "img": require("./images/83.jpg"),
        "type": "大神上分",
        "line": true,
    },
    {
        "name": "走召惡野侽子λ",
        "gameName": "和平精英",
        "price": "28",
        "img": require("./images/84.jpg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "﹋臉红の思春期",
        "gameName": "英雄联盟",
        "price": "30",
        "img": require("./images/85.jpg"),
        "type": "大神上分",
        "line": true,
    },
    {
        "name": "ざηì風飞向尒",
        "gameName": "王者荣耀",
        "price": "22",
        "img": require("./images/86.jpg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "哆心症の梦",
        "gameName": "和平精英",
        "price": "28",
        "img": require("./images/87.jpg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "|被宠壞の猫",
        "gameName": "英雄联盟",
        "price": "28",
        "img": require("./images/88.png"),
        "type": "大神上分",
        "line": true,
    },
    {
        "name": "吃梦のふ兽",
        "gameName": "王者荣耀",
        "price": "23",
        "img": require("./images/89.jpg"),
        "type": "大神上分",
        "line": true,
    },
    {
        "name": "Δベ亂丗ふ魔ην",
        "gameName": "和平精英",
        "price": "28",
        "img": require("./images/90.jpg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "ρ^小(~)女巫婆⌒",
        "gameName": "王者荣耀",
        "price": "28",
        "img": require("./images/91.jpg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "汙界学ぜ未",
        "gameName": "和平精英",
        "price": "25",
        "img": require("./images/92.jpg"),
        "type": "大神上分",
        "line": true,
    },
    {
        "name": "⒈个亽酷至リ污",
        "gameName": "王者荣耀",
        "price": "28",
        "img": require("./images/93.jpg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "情深特另リの亾У",
        "gameName": "英雄联盟",
        "price": "30",
        "img": require("./images/94.jpg"),
        "type": "大神上分"
    },
    {
        "name": "逗逗",
        "gameName": "王者荣耀",
        "price": "30",
        "img": require("./images/1.gif"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "甦宥?爱LOL",
        "gameName": "英雄联盟",
        "price": "30",
        "img": require("./images/2.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "叙利亚女悍匪",
        "gameName": "王者荣耀",
        "price": "30",
        "img": require("./images/3.jpeg"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "夏天妹妹",
        "gameName": "和平精英",
        "price": "30",
        "img": require("./images/4.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "??-可耐",
        "gameName": "英雄联盟",
        "price": "26",
        "img": require("./images/5.jpg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "忆兮兮",
        "gameName": "王者荣耀",
        "price": "28",
        "img": require("./images/6.jpg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "林有有???简单单逗",
        "gameName": "王者荣耀",
        "price": "30",
        "img": require("./images/7.jpeg"),
        "type": "大神上分",
        "line": true,
    },
    {
        "name": "不吃鱼的宝宝吖",
        "gameName": "王者荣耀",
        "price": "22",
        "img": require("./images/8.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "拉米",
        "gameName": "和平精英",
        "price": "28",
        "img": require("./images/9.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "?·Awm°",
        "gameName": "英雄联盟",
        "price": "28",
        "img": require("./images/10.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "云逸.晚八点",
        "gameName": "王者荣耀",
        "price": "23",
        "img": require("./images/11.jpeg"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "尼古拉斯痛痛",
        "gameName": "和平精英",
        "price": "28",
        "img": require("./images/12.jpeg"),
        "type": "大神上分",
        "line": false,
    },
    {
        "name": "不见不散",
        "gameName": "王者荣耀",
        "price": "28",
        "img": require("./images/13.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "小周周",
        "gameName": "王者荣耀",
        "price": "25",
        "img": require("./images/14.gif"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "jk少女",
        "gameName": "王者荣耀",
        "price": "28",
        "img": require("./images/15.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "GD啦啦",
        "gameName": "和平精英",
        "price": "30",
        "img": require("./images/16.jpeg"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "糯糯-米米",
        "gameName": "王者荣耀",
        "price": "28",
        "img": require("./images/17.jpg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "夏颜",
        "gameName": "和平精英",
        "price": "25",
        "img": require("./images/18.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "Rz-zozo?小鬼",
        "gameName": "英雄联盟",
        "price": "25",
        "img": require("./images/19.jpg"),
        "type": "女神陪玩",
        "line": true,
    },
    {
        "name": "金鱼姬",
        "gameName": "英雄联盟",
        "price": "28",
        "img": require("./images/20.jpeg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "聚什么星",
        "gameName": "王者荣耀",
        "price": "25",
        "img": require("./images/21.jpg"),
        "type": "女神陪玩",
        "line": false,
    },
    {
        "name": "满满",
        "gameName": "和平精英",
        "price": "27",
        "img": require("./images/22.jpg"),
        "type": "女神陪玩",
        "line": false,
    }
    ]
}

export default {
    data
}